import React from "react";
import "./SingleDeathNotice.scss";
import $ from "jquery";
import {Link} from "react-router-dom";
import MyContext from "../MyContext";
import candle from "../../res/images/death_notices/candle.png";
import flower from "../../res/images/death_notices/flower.png";
import note from "../../res/images/death_notices/1177948.png";
import survived from "../../res/images/death_notices/survived.jpg";
import { ButtonTheme2, ButtonTheme1, ButtonTheme3, ButtonTheme4 } from "../../res/styler/ButtonThemes/ButtonThemes";
import {FaMapMarkerAlt, FaYoutube} from 'react-icons/fa';
import {GoReport} from 'react-icons/go';
import {BsChevronDoubleDown, BsChevronDoubleUp} from 'react-icons/bs';
import {ImArrowRight2} from 'react-icons/im';
import {MdOutlineArrowBackIos, MdOutlineKeyboardBackspace , MdOutlineShare, MdOutlinePrint} from 'react-icons/md';
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import {RiDoubleQuotesL,RiDoubleQuotesR} from "react-icons/ri";
import Footer from "../Footer/Footer";
import 'react-multi-carousel/lib/styles.css';
import logo from "../../res/images/Amrityum_01.png";
import player_overlay from "../../res/images/death_notices/youtube_overlay.png"; 
import default_image from "../../res/images/user_icon.png";
import 'react-multi-carousel/lib/styles.css';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ImageUpload from "../../res/styler/ImageUpload/ImageUpload";
import memories from "../../res/images/memorials/default_memories.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import user_img from "../../res/images/user_img.jpg";

class SingleDeathNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      death_notice: {
        first_name: "",
        surname : "",
        serial_no : "",
        date_of_birth: "",
        date_of_passing: "",
        locality: "",
        image_src: ""
      },
      family_notice_mapped_id : "",
      service_video_id : "",
      survived_peoples : "",
      predeceased_peoples : "",
      tribute_type: "candle",
      prayer_tribute: "",
      message_tribute: "",
      death_notice_memoirs: [],
      showing_memoirs : [],
      tribute_text: "",
      editorState: EditorState.createEmpty(),
      tribute_image_id : "",
      death_notice_messages: [],
      showing_condolence_messages : [],
      death_notice_tributes: [],
      showing_mementos : [],
      death_notice_prayers: [],
      showing_prayers : [],
      visitation_details: [],
      funeral_details: [],
      committal_details: [],
      general_condolence_messages : [],
      general_prayers : [],
      survived_peoples : [],
      predeceased_peoples : [],
      name : "",
      email : "",
      message : "",
      candle_images : [],
      flower_images : [],
      current_tribute_image : "",
      reason : "",
      charity : {},
      preview : "",
      reject_reason: "",
      captcha_token: "",
      page_share_url : "",
      fullname : ""
    }
    this.image_change = this.image_change.bind(this);
    this.delete_image = this.delete_image.bind(this);
    this.form_change = this.form_change.bind(this);
    this.contact_form_change = this.contact_form_change.bind(this);
    this.show_form = this.show_form.bind(this);
    this.hide_form = this.hide_form.bind(this);
    this.message_change = this.message_change.bind(this);
    this.report_message = this.report_message.bind(this);
    this.show_more_message = this.show_more_message.bind(this);
    this.tribute_type_change = this.tribute_type_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.tribute_form_submit = this.tribute_form_submit.bind(this);
    this.contact_admin_form_submit = this.contact_admin_form_submit.bind(this);
    this.services_onclick = this.services_onclick.bind(this);
    this.service_video_change = this.service_video_change.bind(this);
    this.on_like_button_click = this.on_like_button_click.bind(this);
    this.show_report_page_form = this.show_report_page_form.bind(this);
    this.report_page_form_submit = this.report_page_form_submit.bind(this);
    this.bind_likes = this.bind_likes.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.approveDeathNotice = this.approveDeathNotice.bind(this);
    this.rejectDeathNotice = this.rejectDeathNotice.bind(this);
    this.reject_form_submit = this.reject_form_submit.bind(this);
    this.single_death_notice_page_Ref = React.createRef();
    this.death_notice_ref = React.createRef();
    this.family_details_ref = React.createRef();
    this.funeral_details_ref = React.createRef();
    this.condolence_ref = React.createRef();
    this.mementos_ref = React.createRef();
    this.prayer_ref = React.createRef();
    this.memoirs_ref = React.createRef();
    this.contact_admin_ref = React.createRef();
    this.visitation_ref = React.createRef();
    this.funeral_ref = React.createRef();
    this.committal_ref = React.createRef();
    this.service_video_player_container_ref = React.createRef();
    this.tribute_textarea_ref = React.createRef();
    this.contact_ref = React.createRef(); 
    this.donate_ref = React.createRef(); 
    this.about_ref = React.createRef();
    this.reject_reason_container_ref = React.createRef();
    this.captchaRef = React.createRef();
    this.condolence_limit = 3;
    this.mementos_limit = 3;
    this.prayer_limit = 3;
    this.memoirs_limit = 3;
    this.user_likes = [];
    this.user_like_count = [];
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.disclaimer_text = "Please write your correct name and other information requested. Kindly do not post any personal, abusive, defamatory, infringing, obscene, indecent, discriminatory, or unlawful or similar comments. Amrityum.com will not be responsible for any defamatory message posted under this notice. Please note that sending false messages to insult, defame, intimidate, mislead, or deceive people or to intentionally cause public disorder is punishable under law. It is obligatory on amrityum.com to provide all details we hold of senders of such comments, to the authority concerned upon request. Hence, sending offensive comments using amrityum will be purely at your own risk, and in no way will amrityum.com be held responsible."
    this.visitation_video_id = "";
    this.funeral_video_id = "";
    this.committal_video_id = "";
  }
 
  componentDidMount() {
    // window.addEventListener('beforeprint', (event) => {
    //   this.setState({
    //     showing_condolence_messages : this.state.death_notice_messages
    //   });
    // });
    // if(this.props.history.location.state && typeof this.props.history.location.state == "object"){
    //   this.setState(this.props.history.location.state);
    //   this.single_death_notice_page_Ref.current.style.visibility = "visible";
    //   this.single_death_notice_page_Ref.current.style.opacity = 1;
    // }else{ 
    //  console.log("sldfd")
    // }
    // return;
    if(window.location.pathname == "/DeathNotice/Preview/"+this.props.match.params.death_notice_id){
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/previewDeathNoticeDetails",
        data: this.context.bind_session({
          'death_notice_id': this.props.match.params.death_notice_id,
          'email' :this.context.session.email
        }),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        if (return_data.status == "success"){
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const survived_peoples = return_data.death_notice_family_details.filter((item) =>  item.type == "survived");
          const predeceased_peoples = return_data.death_notice_family_details.filter((item) =>  item.type == "predeceased");
          
          const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
          const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
          const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
          const death_notice_memoirs = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs");
          obj_this.bind_likes(death_notice_messages,"condolence");
          obj_this.bind_likes(death_notice_tributes,"tribute");
          obj_this.bind_likes(death_notice_prayers,"prayer");
          obj_this.bind_likes(death_notice_memoirs,"memoirs");
          
          const visitation_details = return_data.death_notice_other_details.filter((item) => item.type == "visitation");
          const funeral_details = return_data.death_notice_other_details.filter((item) => item.type == "funeral");
          const committal_details = return_data.death_notice_other_details.filter((item) => item.type == "committal");
  
          time_format_conversion(visitation_details);
          time_format_conversion(funeral_details);
          time_format_conversion(committal_details);
          function time_format_conversion(program_details){
            program_details.map((item) => {
              item.start_time = tConvert(item.start_time);
              item.start_time = item.start_time.toString().replace(/:\d\d([ AP]|$)/,'$1');
              item.end_time = tConvert(item.end_time).toString().replace(/:\d\d([ AP]|$)/,'$1');
            });
          }
          function tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
          
            if (time.length > 1) { // If time format correct
              time = time.slice (1);  // Remove full string match value
              time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
              time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
          }

          //find default youtube video
          var visitation_video_id = "",funeral_video_id = "", committal_video_id = "";
          if(visitation_details.length){
            for(let i =0;i<visitation_details.length;i++){
              if(visitation_details[i]['video_id']){
                visitation_video_id = visitation_details[i]['video_id'];
                break;
              }
            }
          }
          if(funeral_details.length){
            for(let i =0;i<funeral_details.length;i++){
              if(funeral_details[i]['video_id']){
                funeral_video_id = funeral_details[i]['video_id'];
                break;
              }
            }
          }
          if(committal_details.length){
            for(let i =0;i<committal_details.length;i++){
              if(committal_details[i]['video_id']){
                committal_video_id = committal_details[i]['video_id'];
                break;
              }
            }
          }
          obj_this.visitation_video_id = visitation_video_id?visitation_video_id:funeral_video_id?funeral_video_id:committal_video_id; 
          obj_this.funeral_video_id = funeral_video_id?funeral_video_id:visitation_video_id?visitation_video_id:committal_video_id; 
          obj_this.committal_video_id = committal_video_id?committal_video_id:funeral_video_id?funeral_video_id:visitation_video_id?visitation_video_id:""; 
          find_short_full_age(return_data.death_notice);
          
          obj_this.setState({
            death_notice: return_data.death_notice,
            death_notice_tributes: death_notice_tributes,
            showing_mementos : death_notice_tributes.slice(0,obj_this.mementos_limit),
            death_notice_messages :death_notice_messages,
            survived_peoples : survived_peoples,
            predeceased_peoples : predeceased_peoples,
            showing_condolence_messages : death_notice_messages.slice(0,obj_this.condolence_limit),
            death_notice_prayers : death_notice_prayers,
            showing_prayers : death_notice_prayers.slice(0,obj_this.prayer_limit),
            death_notice_memoirs : death_notice_memoirs,
            showing_memoirs: death_notice_memoirs.slice(0,obj_this.memoirs_limit),
            visitation_details : visitation_details,
            funeral_details : funeral_details,
            committal_details : committal_details,
            family_notice_mapped_id : return_data.family_notice_mapped_id,
            general_condolence_messages : return_data.general_condolence_messages,
            general_prayers : return_data.general_prayers,
            charity : return_data.charity_details,
            service_video_id : obj_this.visitation_video_id,
            candle_images : return_data.candle_images,
            flower_images : return_data.flower_images,
            current_tribute_image : return_data.candle_images[0]['tribute_type_image'],
            tribute_image_id : return_data.candle_images[0]['id'],
            preview : 1
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_death_notice_page_Ref.current.style.visibility = "visible";
            obj_this.single_death_notice_page_Ref.current.style.opacity = 1;
            const elem = $(".death_notice_block .content_container .content");
            const divHeight = +elem.height();  
            // var lineHeight = +elem.css("line-height").replace('px', '');  
            // const lines = divHeight / lineHeight;
            // console.log(divHeight,lineHeight); 
            console.log(divHeight);
            if(divHeight>250){
              elem.css("height","250px");
              $(".read_more_less_button").css("display","inline-block");
            }
            $(".header_container").css("pointer-events","none");
            $(".published_by, .like_button, .single_charity, .report_button, .button_theme2_container, .all_charity_button, .donate_now_button, .custom_button, .memory_button, .save_message_button, .new_death_notice_message_button, .single_tribute_type").css("pointer-events","none");
            $(".buttons_container").css("display","none");
            window.scrollTo({
              top : obj_this.single_death_notice_page_Ref.current.offsetTop - 80,
              behavior : "smooth"
            });
          });
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error!!");
      });
    }else{
      this.context.loading(1);
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getDeathNoticeDetails",
        data: {
          'death_notice_id': this.props.match.params.death_notice_id,
          'email' :this.context.session.email
        },
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success"){
          if(return_data.death_notice.status == "DELETED"){
            obj_this.props.history.push('/DeathNotices')
          }
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const survived_peoples = return_data.death_notice_family_details.filter((item) =>  item.type == "survived");
          const predeceased_peoples = return_data.death_notice_family_details.filter((item) =>  item.type == "predeceased");
          const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
          const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
          const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
          const death_notice_memoirs = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs");
          obj_this.bind_likes(death_notice_messages,"condolence");
          obj_this.bind_likes(death_notice_tributes,"tribute");
          obj_this.bind_likes(death_notice_prayers,"prayer");
          obj_this.bind_likes(death_notice_memoirs,"memoirs");
          
          const visitation_details = return_data.death_notice_other_details.filter((item) => item.type == "visitation");
          const funeral_details = return_data.death_notice_other_details.filter((item) => item.type == "funeral");
          const committal_details = return_data.death_notice_other_details.filter((item) => item.type == "committal");
  
          time_format_conversion(visitation_details);
          time_format_conversion(funeral_details);
          time_format_conversion(committal_details);
          function time_format_conversion(program_details){
            program_details.map((item) => {
              item.start_time = tConvert(item.start_time);
              item.start_time = item.start_time.toString().replace(/:\d\d([ AP]|$)/,'$1');
              item.end_time = tConvert(item.end_time).toString().replace(/:\d\d([ AP]|$)/,'$1');
            });
          }
          function tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
          
            if (time.length > 1) { // If time format correct
              time = time.slice (1);  // Remove full string match value
              time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
              time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
          }

          //find default youtube video
          var visitation_video_id = "",funeral_video_id = "", committal_video_id = "";
          if(visitation_details.length){
            for(let i =0;i<visitation_details.length;i++){
              if(visitation_details[i]['video_id']){
                visitation_video_id = visitation_details[i]['video_id'];
                break;
              }
            }
          }
          if(funeral_details.length){
            for(let i =0;i<funeral_details.length;i++){
              if(funeral_details[i]['video_id']){
                funeral_video_id = funeral_details[i]['video_id'];
                break;
              }
            }
          }
          if(committal_details.length){
            for(let i =0;i<committal_details.length;i++){
              if(committal_details[i]['video_id']){
                committal_video_id = committal_details[i]['video_id'];
                break;
              }
            }
          }
          obj_this.visitation_video_id = visitation_video_id?visitation_video_id:funeral_video_id?funeral_video_id:committal_video_id; 
          obj_this.funeral_video_id = funeral_video_id?funeral_video_id:visitation_video_id?visitation_video_id:committal_video_id; 
          obj_this.committal_video_id = committal_video_id?committal_video_id:funeral_video_id?funeral_video_id:visitation_video_id?visitation_video_id:""; 
          // console.log(obj_this.visitation_video_id);
         
          find_short_full_age(return_data.death_notice);
          obj_this.setState({
            death_notice: return_data.death_notice,
            death_notice_tributes: death_notice_tributes,
            showing_mementos : death_notice_tributes.slice(0,obj_this.mementos_limit),
            death_notice_messages :death_notice_messages,
            survived_peoples : survived_peoples,
            predeceased_peoples : predeceased_peoples,
            showing_condolence_messages : death_notice_messages.slice(0,obj_this.condolence_limit),
            death_notice_prayers : death_notice_prayers,
            showing_prayers : death_notice_prayers.slice(0,obj_this.prayer_limit),
            death_notice_memoirs : death_notice_memoirs,
            showing_memoirs: death_notice_memoirs.slice(0,obj_this.memoirs_limit),
            visitation_details : visitation_details,
            funeral_details : funeral_details,
            committal_details : committal_details,
            family_notice_mapped_id : return_data.family_notice_mapped_id,
            general_condolence_messages : return_data.general_condolence_messages,
            general_prayers : return_data.general_prayers,
            charity : return_data.charity_details,
            service_video_id : obj_this.visitation_video_id,
            candle_images : return_data.candle_images,
            flower_images : return_data.flower_images,
            current_tribute_image : return_data.candle_images[0]['tribute_type_image'],
            tribute_image_id : return_data.candle_images[0]['id'],
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_death_notice_page_Ref.current.style.visibility = "visible";
            obj_this.single_death_notice_page_Ref.current.style.opacity = 1;
            const elem = $(".death_notice_block .content_container .content");
            const divHeight = +elem.height();  
            // var lineHeight = +elem.css("line-height").replace('px', '');  
            // const lines = divHeight / lineHeight;
            // console.log(divHeight,lineHeight); 
            console.log(divHeight);
            if(divHeight>250){
              elem.css("height","250px");
              $(".read_more_less_button").css("display","inline-block");
            }
            
            var nick_name = "";
            if(obj_this.state.death_notice.nick_name){
              nick_name = "("+obj_this.state.death_notice.nick_name.replace(/\s+/g, '-')+")";
            }
            var family_or_house_name = "";
            if(obj_this.state.death_notice.family_or_house_name){
              family_or_house_name = "-"+obj_this.state.death_notice.family_or_house_name.replace(/\s+/g, '-');
            }
            var age = obj_this.state.death_notice.age?"("+obj_this.state.death_notice.age.replace(/\s+/g, '-')+")":"";
            var middle_name = obj_this.state.death_notice.middle_name?"-"+obj_this.state.death_notice.middle_name.replace(/\s+/g, '-'):"";
            var page_share_url = process.env.REACT_APP_SHARE_URL+"/DeathNotices/"+obj_this.state.death_notice.id+"/"+obj_this.state.death_notice.first_name.replace(/\s+/g, '-')+middle_name+"-"+obj_this.state.death_notice.surname.replace(/\s+/g, '-')+family_or_house_name+nick_name+age+"/"+obj_this.state.death_notice.locality.replace(/\s+/g, '-')+"/"+obj_this.state.death_notice.serial_no
            var fullname = obj_this.state.death_notice.middle_name?" "+obj_this.state.death_notice.middle_name:"";
            var house_name = obj_this.state.death_notice.family_or_house_name?" "+obj_this.state.death_notice.family_or_house_name:"";
            fullname = obj_this.state.death_notice.first_name+fullname+" "+obj_this.state.death_notice.surname+house_name;
            // console.log(fullname);
            obj_this.setState({
              page_share_url : page_share_url,
              fullname : fullname
            });
            window.scrollTo({
              top : obj_this.single_death_notice_page_Ref.current.offsetTop - 80,
              behavior : "smooth"
            });
            obj_this.props.history.replace("/DeathNotices/"+obj_this.state.death_notice.id+"/"+obj_this.state.death_notice.first_name.replace(/\s+/g, '-')+middle_name+"-"+obj_this.state.death_notice.surname.replace(/\s+/g, '-')+family_or_house_name+nick_name+age+"/"+obj_this.state.death_notice.locality.replace(/\s+/g, '-')+"/"+obj_this.state.death_notice.serial_no);
          });
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error!!");
      }); 
    }

    function find_short_full_age(death_notice){
      if(death_notice.below_one_year){
        var age_in_months = death_notice.age_in_months;
        var short_age ="", full_age="";
        if(age_in_months){
          age_in_months = age_in_months>1?age_in_months+" Months":age_in_months+" Month";
          short_age = age_in_months;
          full_age = age_in_months+" ";
        }
        var age_in_days = death_notice.age_in_days;
        if(age_in_days){
          age_in_days = age_in_days>1?age_in_days+" Days":age_in_days+" Day";
          short_age = short_age?short_age:age_in_days;
          full_age+=age_in_days;
        }
        // console.log(short_age, full_age)
        obj_this.setState({
          short_age : short_age,
          full_age : full_age,
        })
      }
    }

    // console.log(window.navigator.userAgent.toLowerCase());
    setTimeout(function(){
      moveSidebar();
    },500);
    
    function moveSidebar(){
      var sidebar_left = $(".death_notice_sidebar_container .sidebar").offset().left;
      var sidebar_width = $(".death_notice_sidebar_container .sidebar").outerWidth();
      // console.log(sidebar_width);
      $(".death_notice_sidebar_container .sidebar").css({
        position:"fixed",
        top : 100,
        left : sidebar_left,
        width : "300"
      });
    }

    window.addEventListener('resize', onWindowResize);
    function onWindowResize(){
      $(".death_notice_sidebar_container .sidebar").css({
        position:"relative",
        top : "",
        left : "",
        width : ""
      });
      moveSidebar();
    }

    $(".toggles_bottom_scroll_icon").on("click",function(){
      // console.log($(".toggles_container").scrollTop());
      const scrolled_distance = $(".toggles_container").scrollTop();
      document.getElementsByClassName("toggles_container")[0].scroll({
        top: (scrolled_distance+170), 
        left: 0, 
        behavior: 'smooth' 
      });
    });

    $(".toggles_top_scroll_icon").on("click",function(){
      const scrolled_distance = $(".toggles_container").scrollTop();
      document.getElementsByClassName("toggles_container")[0].scroll({
        top: (scrolled_distance-170), 
        left: 0, 
        behavior: 'smooth' 
      });
    });

    $(".toggles_container").on("scroll",function(){
      if($(this).scrollTop() > 250){
        $(".toggles_bottom_scroll_icon").css("animation","blinker 0s linear infinite");
        $(".toggles_top_scroll_icon").css("animation","blinker 1s linear infinite");
      }
      if($(this).scrollTop() == 0){
        $(".toggles_top_scroll_icon").css("animation","blinker 0s linear infinite");
        $(".toggles_bottom_scroll_icon").css("animation","blinker 1s linear infinite");
      }
    });

    $(".read_more_less_button").on("click",function(){
      if($(this).html() == "Read More"){
        $(this).html("Read Less");
        $(".content_container .content").heightAuto();
      }else{
        $(this).html("Read More");
        $(".content_container .content").css("height","250px");
      }
    });

    $(".show_hide_messages_button").on("click", function(){
      if(obj_this.context.session){
        if($(this).html() == "SHOW LESS"){
          $(".general_messages_container").css({height :"0px",overflow :"hidden"});
          $(this).html("Show default messages");
        }else{
          $(".general_messages_container").heightAuto();
          $(".general_messages_container").css({overflow :"unset"});
          $(this).html("SHOW LESS");
        }
      }else{
        obj_this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
      }
    });

    $(".show_hide_prayers_button").on("click", function(){
      if(obj_this.context.session){
        if($(this).html() == "SHOW LESS"){
          $(".general_prayers_container").css({height :"0px",overflow :"hidden"});
          $(this).html("Show default prayers");
        }else{
          $(".general_prayers_container").heightAuto();
          $(".general_prayers_container").css({overflow :"unset"});
          $(this).html("SHOW LESS");
        }
      }else{
        obj_this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
      }
    });

    $(".show_hide_memories_button").on("click", function(){
      $(".new_memory_story_form").css({"height":"0px", "overflow":"hidden"});
      $(".new_memorial_message_button").css("display","inline-block");
      obj_this.setState({editorState: EditorState.createEmpty(), image: "", image_src: ""});
      $(".delete_button").trigger("click");
    });

    var text = obj_this.disclaimer_text.slice(0, 197);
    $(".disclaimer_text").text(text);
    $(".read_or_hide_disclaimer").on("click",function(){
      $(this).parents("form").css("height","auto");
      var text = $(this).siblings(".disclaimer_text").text();
      if($(this).text() == "Read More"){
        text = obj_this.disclaimer_text;
        $(this).text("Read Less");
      }else{
        text = text.slice(0, 197);
        $(this).text("Read More");
      }
      $(this).siblings(".disclaimer_text").text(text);
    });
  }
  

  componentDidUpdate(){
    $(".footer_container").css("display","none");
  }

  componentWillUnmount(){
    $(".footer_container").css("display","block");
    $(".header_container").css("pointer-events","auto")
  }

  bind_likes(messages,message_type){
    //this function bind likes count and check whether the message is liked or not by logined user
    messages.map((message) => {
      message.liked = 0;
      message.like_count = 0;
      this.user_likes.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            if(this.context.session){
              message.liked = 1;
            }
          }
        }
      });
      this.user_like_count.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            message.like_count = item.like_count;
          }
        }
      });
    });
  }

  toggleChange(e,container_ref){
    this.setState({
      tribute_text : ""
    });
    document.querySelectorAll('.toggle').forEach(element => {
      element.classList.remove("toggle_selected"); 
    }); 
    e.target.classList.add("toggle_selected"); 
    e.target.scrollIntoView(); 
    window.scroll({
      top: (container_ref.current.offsetTop-20), 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  services_onclick(service_type){
    // $(".other_details_container .service_description").css("height","0px");
    // setTimeout(function(){
    //   $(".other_details_container .service_description").css("height","");
    // },150);
    console.log(service_type);
    if(service_type == "contact" || service_type == "instructions"){
      this.setState({
        service_video_id : ""
      });
    }else{
      if(service_type == "visitation"){
        this.setState({
          service_video_id : this.visitation_video_id
        });
      }else if(service_type == "funeral"){
        this.setState({
          service_video_id : this.funeral_video_id
        });
      }else if(service_type == "committal"){
        this.setState({
          service_video_id : this.committal_video_id
        });
      }
    }
    // console.log(this.committal_video_id)
    $(".service_toggle").removeClass("service_toggle_selected");
    $("."+service_type+"_toggle").addClass("service_toggle_selected");
    $(".services_container").css({"height":"0","opacity" : 0});
    $("."+service_type+"_service" )[0].scrollTo({ top : $("."+service_type+"_service" )[0].offsetTop, behavior : "smooth" });
    $("."+service_type+"_service").heightAuto();
    $("."+service_type+"_service").css("opacity","1");
  }

  service_video_change(url){
    this.setState({
      service_video_id : url
    },() => {
      this.service_video_player_container_ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    });
  }

  tribute_type_change(e) {
    // console.log(e.target.value);
    var current_tribute_image;
    var tribute_image_id;
    if(e.target.value == "flower"){
      current_tribute_image = this.state.flower_images[0].tribute_type_image;
      tribute_image_id = this.state.flower_images[0].id;
    }else if(e.target.value == "candle"){
      current_tribute_image = this.state.candle_images[0].tribute_type_image;
      tribute_image_id = this.state.candle_images[0].id;
    }
    this.setState({
      tribute_type: e.target.value,
      current_tribute_image: current_tribute_image,
      tribute_image_id : tribute_image_id
    });
  }

  tribute_image_change(item){
    this.setState({
      current_tribute_image : item.tribute_type_image,
      tribute_image_id : item.id
    })
  }

  show_form(e,type){
    this.setState({
      tribute_text : ""
    });
    if(this.context.session){
      $("."+type+"_form").heightAuto();
      $("."+type+"_form").css("overflow","unset");
      $("."+type+"_button").css("display","none");
    }else{
      if(type == "condolence"){
        this.context.confirm("Sign in to leave a Condolence",() => {
          this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
        });
        return;
      }else if(type == "prayer"){
        this.context.confirm("Sign in to leave a Prayer",() => {
          this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
        });
        return;
      }else if(type == "memory"){
        this.context.confirm("Sign in to leave a Memoirs",() => {
          this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
        });
        return;
      }
      // this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
    }
  }

  hide_form(type){
    this.setState({
      tribute_text : "",
      message_tribute : "",
      prayer_tribute : ""
    });
    $("."+type+"_form").css({ height:"0px", overflow:"hidden"});
    $("."+type+"_button").css("display","inline-block");
    if(type == "condolence"){
      $(".general_messages_container").css({height :"0px",overflow :"hidden"});
      $(".show_hide_messages_button").html("Show default messages");
    }else if (type == "prayer"){
      $(".general_prayers_container").css({height :"0px",overflow :"hidden"});
      $(".show_hide_prayers_button").html("Show default prayers");
    }
  }

  form_change(e,type) {
    if(type == "prayer"){
      if(e.target.value.length > 700){
        $.alert("Maximum characters exceeded");
        return;
      }
    }else if(type == "condolence"){
      if(e.target.value.length > 250){
        $.alert("Maximum characters exceeded");
        return;
      }
    }
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  onEditorStateChange(value){
    // console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({
        editorState :value,
        tribute_text : draftToHtml(convertToRaw(value.getCurrentContent()))
    });
  };

  contact_form_change(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  message_change(e){
    // console.log(e.target.textContent);
    this.setState({
      message_tribute : e.target.textContent
    },() => {
      this.condolence_ref.current.scrollIntoView({ block: "start", inline: "center"});
    });
  }

  prayer_change(e){
    // console.log(e.target.textContent);
    this.setState({
      prayer_tribute : e.target.textContent
    },() => {
      this.prayer_ref.current.scrollIntoView({ block: "start", inline: "center"});
    });
  }

  on_like_button_click(id,type,e){
    
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.props.match.params.death_notice_id})
      });
      return;
    }
    // console.log(this.context.session);
    // return;
    // console.log($(e.currentTarget).find(".like_button_label").html())
    if($(e.currentTarget).find(".like_button_label").html() == "Like"){
      //like
      var obj_this = this;
      this.context.loading(1);
      const liked_content = {
        notice_type: "death_notice" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/likeContent",
        data: this.context.bind_session({liked_content : JSON.stringify(liked_content)}),
        type: "POST"
      }).done(function (return_data){
        // console.log(return_data);
        // return;
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if(type == "condolence"){
            const death_notice_messages = obj_this.state.death_notice_messages;
            death_notice_messages.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
              // console.log(item);
            });
            obj_this.setState({
              death_notice_messages :death_notice_messages
            });
          }else if(type == "tribute"){
            const death_notice_tributes = obj_this.state.death_notice_tributes;
            death_notice_tributes.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              death_notice_tributes :death_notice_tributes
            });
          }else if(type == "prayer"){
            const death_notice_prayers = obj_this.state.death_notice_prayers;
            death_notice_prayers.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              death_notice_prayers :death_notice_prayers
            });
          }else if(type == "memoirs"){
            const death_notice_memoirs = obj_this.state.death_notice_memoirs;
            death_notice_memoirs.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              death_notice_memoirs :death_notice_memoirs
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+obj_this.props.match.params.death_notice_id})
        }else{
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }else{
      //dislike
      var obj_this = this;
      this.context.loading(1);
      const disliked_content = {
        notice_type: "death_notice" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/disLikeContent",
        data: this.context.bind_session({disliked_content : JSON.stringify(disliked_content)}),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if(type == "condolence"){
            const death_notice_messages = obj_this.state.death_notice_messages;
            death_notice_messages.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              death_notice_messages :death_notice_messages
            });
          }else if(type == "tribute"){
            const death_notice_tributes = obj_this.state.death_notice_tributes;
            death_notice_tributes.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              death_notice_tributes :death_notice_tributes
            });
          }else if(type == "prayer"){
            const death_notice_prayers = obj_this.state.death_notice_prayers;
            death_notice_prayers.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              death_notice_prayers :death_notice_prayers
            });
          }else if(type == "memoirs"){
            const death_notice_memoirs = obj_this.state.death_notice_memoirs;
            death_notice_memoirs.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              death_notice_memoirs :death_notice_memoirs
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+obj_this.props.match.params.death_notice_id})
        }else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }
  }

  report_message(id,type){
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.props.match.params.death_notice_id})
      });
      return;
    }

    this.context.confirm("Report this content",() => {
      var obj_this = this;
      this.context.loading(1);
      const report_data = {
        death_notice_id: this.state.death_notice.id ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/reportDeathNoticeContent",
        data: this.context.bind_session({report_data : JSON.stringify(report_data)}),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          $.alert("Your request will be reviewed.");
        }else if(return_data.status == "session failed"){
          this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.props.match.params.death_notice_id})
        }else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    });
    return;
  }

  show_more_message(type){
    if(type == "condolence"){
      this.condolence_limit+=3;
      this.setState({
        showing_condolence_messages : this.state.death_notice_messages.slice(0,this.condolence_limit)
      },() => {
        if(window.location.pathname == "/DeathNotice/Preview/"+this.props.match.params.death_notice_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "mementos"){
      this.mementos_limit+=3;
      this.setState({
        showing_mementos : this.state.death_notice_tributes.slice(0,this.mementos_limit)
      },() => {
        if(window.location.pathname == "/DeathNotice/Preview/"+this.props.match.params.death_notice_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "prayer"){
      this.prayer_limit+=3;
      this.setState({
        showing_prayers : this.state.death_notice_prayers.slice(0,this.prayer_limit)
      },() => {
        if(window.location.pathname == "/DeathNotice/Preview/"+this.props.match.params.death_notice_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }else if(type == "memories"){
      this.memoirs_limit+=3;
      this.setState({
        showing_memoirs : this.state.death_notice_memoirs.slice(0,this.memoirs_limit)
      },() => {
        if(window.location.pathname == "/DeathNotice/Preview/"+this.props.match.params.death_notice_id){
          $(".like_button, .report_button").css("pointer-events","none");
        }
      });
    }
    
  }

  image_change(e) {
    const file = e.target.files[0];
    // if (file.size / 1024 > 4096)
    //   alert("File size cannot exceed more than 4MB");
    // else
      this.setState({
        image: file,
      },() => {
        $(".memory_form").heightAuto();
      });
  }

  delete_image(){
    this.setState({
      profile_pic_delete_flag : 1,
      image_src : "",
      image : ""
    });
  }

  tribute_form_submit(e,form_type) {
    e.preventDefault();
    if(!this.context.session){
      if(form_type == "tribute"){
        // if(!window.confirm("Sign in to leave Mementoes")){
        //   return;
        // }
        this.context.confirm("Sign in to leave Mementoes",() => {
        this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
          return;
        });
      }
      return;
    }
    if(form_type == "tribute"){
      // if(!window.confirm("Do you want to publish?")){
      //   return;
      // }
      this.context.confirm("Do you want to publish?",() => {
        this.form_submit(form_type);
      });
      return;
    }else{
      this.form_submit(form_type);
    }
    
  }

  form_submit(form_type){
    var tribute_text = "";
    if(form_type == "prayer"){
      tribute_text = this.state.prayer_tribute;
    }else if(form_type == "note"){
      tribute_text = this.state.message_tribute;
    }else if(form_type == "memoirs"){
      tribute_text = this.state.tribute_text;
    }
    console.log(this.state);
    this.context.loading(1);
    var obj_this = this;
    const tribute_data = {
      death_notice_id: this.props.match.params.death_notice_id,
      tribute_text: tribute_text,
      tribute_image_id: this.state.tribute_image_id,
      // email: this.state.email,
      tribute_type: this.state.tribute_type,
      form_type : form_type
    }
    const form_data = new FormData();
    form_data.append("tribute_data", JSON.stringify(tribute_data));
    form_data.append("sid", this.context.session.sid);
    form_data.append("token", this.context.session.token);
    form_data.append("image", this.state.image);
    form_data.append("email", this.context.session.email);
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveDeathNoticeTribute",
      data: form_data,
      type: "POST",
      processData: false,
      contentType: false,
    }).done(function (return_data) {
      console.log(return_data)
      // return;
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        obj_this.mementos_limit = 3;
        obj_this.prayer_limit = 3;
        obj_this.condolence_limit = 3;
        obj_this.user_likes = return_data.user_likes;
        obj_this.user_like_count = return_data.user_like_count;
        const death_notice_messages = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "note");
        const death_notice_tributes = return_data.death_notice_tributes.filter((item) => item.tribute_type == "candle" || item.tribute_type == "flower");
        const death_notice_prayers = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "prayer");
        const death_notice_memoirs = return_data.death_notice_tributes.filter((item) =>  item.tribute_type == "memoirs");
        obj_this.bind_likes(death_notice_messages,"condolence");
        obj_this.bind_likes(death_notice_tributes,"tribute");
        obj_this.bind_likes(death_notice_prayers,"prayer");
        obj_this.bind_likes(death_notice_memoirs,"memoirs");
        if(form_type == "memoirs"){
          $(".new_memory_story_form").css({"height" :"0px", "overflow" : "hidden"});
          $(".new_memorial_message_button").css("display","inline-block");
        }else if(form_type == "note"){
          $(".condolence_form").css({"height": "0px", "overflow" : "hidden"});
          $(".general_messages_container").css({height :"0px",overflow :"hidden"});
          $(".condolence_button").css("display","inline-block");
        }else if(form_type == "prayer"){
          $(".prayer_form").css({"height": "0px", "overflow" : "hidden"});
          $(".general_prayers_container").css({height :"0px",overflow :"hidden"});
          $(".prayer_button").css("display","inline-block");
        }
        obj_this.setState({
          showing_mementos: death_notice_tributes.slice(0,obj_this.mementos_limit),
          death_notice_tributes: death_notice_tributes,
          showing_prayers : death_notice_prayers.slice(0,obj_this.prayer_limit),
          death_notice_prayers : death_notice_prayers,
          showing_condolence_messages : death_notice_messages.slice(0,obj_this.condolence_limit),
          death_notice_messages : death_notice_messages,
          death_notice_memoirs : death_notice_memoirs,
          showing_memoirs : death_notice_memoirs.slice(0,obj_this.memoirs_limit),
          message_tribute: "",
          editorState : EditorState.createEmpty(),
          prayer_tribute: "",
          tribute_text : "",
          image_src : "",
          image: ""
          // written_by: "",
          // email: "",
          // tribute_type: "candle",
        },() => {
          $(".image_modify_buttons .delete_button").trigger("click");
        });
        $(".preview_image").css("background-image","");
        if(return_data.tribute_status == "PUBLISHED"){
          $.alert("Your message is published");
        }else{
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      } else {
        alert("Network error!!");
      }
    }).fail(function () {
      alert("Network error!!");
    });
  }

  contact_admin_form_submit(e){
    e.preventDefault();
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    console.log(this.state);
    var obj_this = this;
    var contact_details = {
      name : this.state.name,
      email : this.state.email,
      message : this.state.message,
      notice_id : this.state.death_notice.id,
      notice_type : "death_notice",
      page_link : window.location.href
    };
    contact_details.captcha_token = token;
    if(contact_details.captcha_token.length == 0){
      $.alert("Please verify you are a human!"); 
      return;
    }
    this.context.loading(1);
    contact_details = JSON.stringify(contact_details);
    $.ajax({
      url : process.env.REACT_APP_API + "/api/savePageAdminContact",
      data : {
        contact_details : contact_details
      },
      type : "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        $.alert("Your message will be showed to this page admin.");
        obj_this.setState({
          name : "",
          email : "",
          message : "",
          captcha_token: "",
        })
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  rejectDeathNotice() {
    if (!window.confirm("Reject this notice")) {
      return;
    }
    this.reject_reason_container_ref.current.style.display = "block";

  }

  reject_form_submit(e) {
    e.preventDefault();
    var obj_this = this
    this.context.loading(1);;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/rejectDeathNotice",
      data: this.context.bind_session({ death_notice_id: this.state.death_notice.id, reject_reason: this.state.reject_reason }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        $.alert("Death Notice rejected.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approveDeathNotice() {
    if (!window.confirm("Approve this notice")) {
      return;
    }
    console.log( this.state.death_notice.id )
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveDeathNotice",
      data: this.context.bind_session({ death_notice_id: this.state.death_notice.id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        $.alert("Death Notice approved.");
        obj_this.props.history.push({
          pathname : obj_this.props.location.url ?? "/Dashboard",
          state : obj_this.props.location.previousState??""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  show_report_page_form(){
    if(this.context.session){
      $(".report_page_form_ghost").css("display","block");
    }else{
      this.context.confirm("Sign in to report this page",() => {
        this.props.history.push({ pathname: "/Login", url : "/DeathNotices/"+this.state.death_notice.id})
      });
      return;
    }
  }

  report_page_form_submit(e){
    e.preventDefault();
    console.log(this.state.reason);
    // return;
    var obj_this = this;
    this.context.loading(1);
    $(".report_page_form_ghost").css("display","none");
    const reported_data = {
      notice_id : this.props.match.params.death_notice_id,
      notice_type : "death_notice",
      reason : this.state.reason
    };
    $.ajax({
      url : process.env.REACT_APP_API+"/api/reportNotice",
      data : this.context.bind_session({
        reported_data : JSON.stringify(reported_data)
      }),
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
          reason : ""
        });
        $.alert("Your message is waiting for administrator approval. Please check back later.");
      }else{
        alert("Something Went Wrong, Please Try Again");
      }
      obj_this.context.loading(0);
    }).fail(function(){
        alert("Network error")
    });
  }

  render() {
    return (
      <>
      <div className="single_death_notice_page_container page_container" ref={this.single_death_notice_page_Ref}>
        <div className="sidebar_details_grid">
          <div className="left_gap"></div>
          <div className="death_notice_details_container">
            <div className="preview_title" style={{display:this.state.preview?"block":"none"}}>Preview</div>
            <div className="single_info_container" 
              id="about"
              ref={this.about_ref}
              style={{paddingTop:"0px"}}
            >
              <div className="single_info_content"
                style={{backgroundColor:"rgba(45, 79, 182, 0.22)"}}>
                <div className="death_notice_image_details_grid">
                  {this.state.death_notice.image_src?
                    <div className="image_container" style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/death_notice_images/"+this.state.death_notice.image_src+"')"}}></div>
                  :
                    <div className="image_container" style={{backgroundImage: "url('"+default_image+"')"}}></div> 
                  }
                  <div className="details_container">
                    <div className="loving_memory_label">In loving memory of</div>
                    <div className="deceased_name"><span >{this.state.death_notice.first_name} {this.state.death_notice.middle_name} {this.state.death_notice.surname} </span><span className="deceased_age" style={{display:this.state.death_notice.age?"inline":"none"}}>({this.state.death_notice.age})</span></div>
                    {this.state.short_age?
                    <div className="date_of_death">({this.state.short_age})</div>
                    :<div className="date_of_death">({new Date(this.state.death_notice.date_of_birth).getFullYear()} - {new Date(this.state.death_notice.date_of_death).getFullYear()})</div>
                      }
                    <div className="mobile_duration">({new Date(this.state.death_notice.date_of_birth).getFullYear()} - {new Date(this.state.death_notice.date_of_death).getFullYear()})</div>
                    <div className="mobile_address_container">
                      <div>{this.state.death_notice.family_or_house_name}</div>
                      <div>{this.state.death_notice.locality}</div>
                      <div>{this.state.death_notice.city}</div>
                      <div>{this.state.death_notice.state}</div>
                      <div>{this.state.death_notice.country}</div>
                    </div>
                    <p className="notice_content">This Amrityum profile page is affectionately dedicated to 
                      sharing the death notice and funeral information on the passing 
                      of <b>{this.state.death_notice.first_name}{this.state.death_notice.middle_name?" "+this.state.death_notice.middle_name:""}{this.state.death_notice.surname?" "+this.state.death_notice.surname:""}
                      &nbsp;({this.state.death_notice.age?+this.state.death_notice.age: this.state.full_age})</b>
                      &nbsp;of {this.state.death_notice.family_or_house_name?this.state.death_notice.family_or_house_name+", ":""} {this.state.death_notice.locality?this.state.death_notice.locality+", ":""} {this.state.death_notice.city?this.state.death_notice.city+", ":""}
                      {this.state.death_notice.state?this.state.death_notice.state+", ":""}{this.state.death_notice.country}
                      .</p>
                    <p className="notice_content">Messages of comfort and condolence may be left for the family in the <span onClick={(e) => this.toggleChange(e,this.condolence_ref)}>Condolence Messages</span> section below.</p>
                    <p className="notice_content">Today and always, may loving memories of {this.state.death_notice.first_name} bring you peace, comfort and strength.</p>
                    {/* <p className="notice_content">Should you wish to pay tribute to  {this.state.death_notice.first_name}'s memory by supporting the <span onClick={(e) => this.toggleChange(e,this.donate_ref)}>charity selected by  {this.state.death_notice.first_name}'s family</span>,
                     you are most welcome to leave a donation. You may also search our <span onClick={(e) => this.props.history.push("/Charities")}>charity partners </span>database to donate in memory. </p> */}
                    <p className="notice_content">On behalf of {this.state.death_notice.first_name}'s family, Amrityum Team would like to thank you for your support during this sad time.</p>
                    <div className="published_by">
                      Funeral Notice published on behalf of the family by&nbsp;
                    <span onClick={() => this.state.death_notice.user_type == "partner" && this.state.death_notice.partner_status == "APPROVED"?this.props.history.push("/Partners/"+this.state.death_notice.partner_id):""}
                      style={{
                        fontWeight: "600",
                        cursor:this.state.death_notice.user_type == "partner"  && this.state.death_notice.partner_status == "APPROVED"?"pointer":"auto",
                        textDecoration:this.state.death_notice.user_type == "partner" && this.state.death_notice.partner_status == "APPROVED"?"underline":""  
                      }}>{this.state.death_notice.user_type == "partner" && this.state.death_notice.partner_status == "APPROVED"?this.state.death_notice.business_name:this.state.death_notice.published_by}</span>
                    </div>
                    <div style={{textAlign:"right",color:"#690090",fontSize:"11px"}}>AMRITYUM ID : {this.state.death_notice.serial_no}</div>
                  </div>
                </div>
                
              </div>
            </div>

            <div className="single_info_container" id="death_notice" ref={this.death_notice_ref}>
                <div className="single_info_content death_notice_block">
                  <div className="single_info_head">
                    <h1>DEATH NOTICE</h1>
                  </div>
                  <div className="single_info_body content_container">
                    <div className="notice_title">The death has occurred of {this.state.death_notice.first_name} {this.state.death_notice.middle_name}{this.state.death_notice.surname?" "+this.state.death_notice.surname:""}{this.state.death_notice.family_or_house_name?", "+this.state.death_notice.family_or_house_name:""}</div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: this.state.death_notice.notice_content }}/>
                    <div className="read_more_less_button">Read More</div>
                  </div>
              </div>
            </div>

            <div className="single_info_container" 
              ref={this.family_details_ref}
              id="family_details"
              // style={{display:this.state.predeceased_peoples.length || this.state.survived_peoples.length?"block":"none"}}
              >
              <div className="single_info_content services_details_block" 
              style={{backgroundColor:"rgba(149, 191, 221, 0.39)"}}>
                <div className="single_info_head">
                  <h1>FAMILY DETAILS</h1>
                </div>
                <div className="single_info_body" >
                <div className="family_details_container">
                  <div className="family_details_grid">
                    <div className="survived_container" >
                      <div className="survived_by_title">Survived by</div>
                      <div className="peoples">
                        {this.state.survived_peoples && this.state.survived_peoples.map((item,idx) => (
                          <div className="people_container" key={idx}>
                            {item.image_src?
                            <div className="people_image"  style={{ backgroundImage: item.image_src?"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/survived_images/"+item.image_src+"')":"url('/images/user_icon.png')"}}></div>
                            :<div className="people_image" style={{backgroundImage: "url('"+survived+"')"}}></div> 
                            }
                            <div className="people_name">{item.name}</div>
                            <div className="people_relation">{item.relation}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="predeceased_container">
                      <div className="predeceased_by_title">Predeceased by</div>
                      <div className="peoples">
                        {this.state.predeceased_peoples && this.state.predeceased_peoples.map((item,idx) => (
                          <div className="people_container" key={idx}>
                          <div className="people_image"  style={{ backgroundImage: item.image_src?"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/predeceased_images/"+item.image_src+"')":"url('/images/user_icon.png')"}}></div>
                          <div className="people_name">{item.name}</div>
                          <div className="people_relation">{item.relation}</div>
                          </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>      
                </div>
              </div>
            </div>
            <div className="single_info_container" 
              ref={this.funeral_details_ref}
              id="funeral_details"
              >
              <div className="single_info_content services_details_block">
                <div className="single_info_head">
                  <h1>FUNERAL DETAILS</h1>
                </div>
                <div className="single_info_body">
                  <div className="video_other_details_grid">
                    <div className="other_details_container">
                      {/* <div className="service_title">Funeral Details</div> */}
                      <div className="service_description">
                      We understand how important it is to share information about the funeral of your loved one.
                      Please find below all relevant details as updated. The information may include online links,
                      directions, emergency contact and special instructions.
                      </div>
                      <div className="service_toggles_container">
                        <div className="service_toggle service_toggle_selected visitation_toggle" 
                          onClick={() => this.services_onclick("visitation")}
                          // style={{"display":this.state.visitation_details.length?"inline-block":"none"}}
                        >Viewing</div>
                        <div className="service_toggle funeral_toggle"
                          onClick={() => this.services_onclick("funeral")}
                          // style={{"display":this.state.funeral_details.length?"inline-block":"none"}}
                        >Funeral Rite</div>
                        <div className="service_toggle committal_toggle"
                          onClick={() => this.services_onclick("committal")}
                          // style={{"display":this.state.committal_details.length?"inline-block":"none"}}  
                        >Final Committal</div>
                        <div className="service_toggle contact_toggle"
                          onClick={() => this.services_onclick("contact")}
                          // style={{"display":this.state.death_notice.contact_name || this.state.death_notice.contact_no?"inline-block":"none"}}
                        >Contact Details</div>
                         <div className="service_toggle instructions_toggle"
                          onClick={() => this.services_onclick("instructions")}
                          // style={{"display":this.state.death_notice.general_notes?"inline-block":"none"}}
                        >Special Instructions</div>
                      </div>
                      <div className="services_block">
                        <div className="services_container visitation_service" ref={this.visitation_ref}>
                        {this.state.visitation_details && this.state.visitation_details.map((item,idx) => (
                          <div className="single_service_container" key={idx}>
                            <div className="program_title">{item.program_title}</div>
                            <div className="service_details">
                              <div  className="date_container">
                                <div className="day">{new Date(item.date).getDate()}</div>
                                <div className="month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                                <div className="border_bottom">{new Date(item.date).getFullYear()}</div>
                              </div>
                              <div className="time_container">{item.start_time} {item.end_time?"-":""} {item.end_time}</div>
                              <div className="address_container">{item.address}</div>
                              <div className="live_video_link">
                                <FaYoutube size={50} color="#ff0000" onClick={() => this.service_video_change(item.video_id)} style={{display:item.video_id?"inline-block":"none"}} /></div>
                              <div className="google_map_link">
                                <a href={item.google_map_url} target={"_blank"} style={{display:item.google_map_url?"inline-block":"none"}}>
                                  <FaMapMarkerAlt size={36} color="#4285f4" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                        </div>
                        <div className="services_container funeral_service" ref={this.funeral_ref}>
                        {this.state.funeral_details && this.state.funeral_details.map((item,idx) => (
                          <div className="single_service_container" key={idx}>
                            <div className="program_title">{item.program_title}</div>
                            <div className="service_details">
                              <div  className="date_container">
                                <div className="day">{new Date(item.date).getDate()}</div>
                                <div className="month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                                <div className="border_bottom">{new Date(item.date).getFullYear()}</div>
                              </div>
                              <div className="time_container">{item.start_time}</div>
                              <div className="address_container">{item.address}</div>
                              <div className="live_video_link">
                                <FaYoutube size={36} color="#ff0000" onClick={() => this.service_video_change(item.video_id)} style={{display:item.video_id?"inline-block":"none"}} /></div>
                              <div className="google_map_link">
                                <a href={item.google_map_url} target={"_blank"} style={{display:item.google_map_url?"inline-block":"none"}}>
                                  <FaMapMarkerAlt size={28} color="#4285f4" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                        </div>
                        <div className="services_container committal_service" ref={this.committal_ref}>
                        {this.state.committal_details && this.state.committal_details.map((item,idx) => (
                          <div className="single_service_container" key={idx}>
                            <div className="service_details">
                              <div  className="date_container">
                                <div className="day">{new Date(item.date).getDate()}</div>
                                <div className="month">{new Date(item.date).toLocaleString('default', { month: 'short' })}</div>
                                <div className="border_bottom">{new Date(item.date).getFullYear()}</div>
                              </div>
                              <div className="time_container">{item.start_time}</div>
                              <div className="address_container">{item.address}</div>
                              <div className="live_video_link">
                                <FaYoutube size={36} color="#ff0000" onClick={() => this.service_video_change(item.video_id)} style={{display:item.video_id?"inline-block":"none"}} /></div>
                              <div className="google_map_link">
                                <a href={item.google_map_url} target={"_blank"} style={{display:item.google_map_url?"inline-block":"none"}}>
                                  <FaMapMarkerAlt size={28} color="#4285f4" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                        </div>
                        <div className="services_container contact_service" ref={this.contact_ref}>
                          <div className="single_service_container" style={{display:this.state.death_notice.contact_name || this.state.death_notice.contact_no?"block":"none"}}>
                            <div className="contact_details_container">
                              <div className="contact_detail_grid" style={{display:this.state.death_notice.contact_name?"grid":"none"}}>
                                <div className="label">Name</div>
                                <div>:</div>
                                <div className="value">{this.state.death_notice.contact_name}</div>
                              </div>
                              <div className="contact_detail_grid" style={{display : this.state.death_notice.contact_no?"grid":"none"}}>
                                <div className="label">Contact No</div>
                                <div>:</div>
                                <div className="value">{this.state.death_notice.contact_no}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="services_container instructions_service" ref={this.contact_ref}>
                          <div className="single_service_container" style={{display:this.state.death_notice.general_notes?"block":'none'}}>
                            <div className="note">{this.state.death_notice.general_notes}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="video_player_container" style={{display:this.state.service_video_id?"block":"none"}} ref={this.service_video_player_container_ref}>
                      {this.state.service_video_id?
                        <iframe src={"https://www.youtube.com/embed/"+this.state.service_video_id} frameBorder="0" allowFullScreen></iframe>
                      :
                        <div className="player_overlay" style={{backgroundImage:"url('"+player_overlay+"')"}} ></div>
                      }   
                    </div>
                  </div>                  
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" 
              ref={this.condolence_ref} 
              id="condolence"
            >
              <div className="single_info_content" 
              style={{backgroundColor:"#c0bbb5"}}>
                <div className="single_info_head">
                  <h1>CONDOLENCE MESSAGES</h1>
                </div>
                <div className="single_info_body" style={{textAlign:"center"}}>
                  <form className="new_tribute_form condolence_form" onSubmit={(e) => this.tribute_form_submit(e,"note")} method="post">
                    <div className="form_body">
                      <div className="tribute_message_grid">
                        <div className="selected_tribute_type_image" style={{ backgroundImage:  "url('"+ note + "')"}}></div>
                        <div className="tribute_message">
                          <textarea
                            name="message_tribute"
                            value={this.state.message_tribute}
                            placeholder="Write your message (Max 250 characters)"
                            rows={5}
                            onChange={(e) => this.form_change(e,"condolence")}
                            required
                            ref={this.tribute_textarea_ref}
                          />
                        </div>
                      </div>
                      <div className="form_footer">
                        <div className="show_hide_messages_button">Show default messages</div>
                        <div className="hide_tribute_form_button" onClick={(e) => {this.hide_form("condolence")}}>HIDE</div>
                        <ButtonTheme2 label="Publish" className="save_tribute_button" />
                      </div>
                      <div className="disclaimer_container" style={{color:"white"}}>
                        <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                        <div className="disclaimer_text" >
                          Please write your correct name and other information requested.
                          Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                            indecent, discriminatory, or unlawful or similar comments. 
                            Amrityum.com will not be responsible for any defamatory message posted 
                            under this notice.
                            
                          Please note that sending false messages to insult, defame, intimidate, mislead,
                            or deceive people or to intentionally cause public disorder is punishable
                            under law. It is obligatory on amrityum.com to provide all details we
                              hold of senders of such comments, to the authority concerned upon request.
                        
                          Hence, sending offensive comments using amrityum will be purely at your own risk,
                            and in no way will amrityum.com be held responsible.
                        </div>
                        <span className="read_or_hide_disclaimer">Read More</span>
                      </div>                    
                    </div>
                  </form>
                  <div className="general_messages_container">
                    {this.state.general_condolence_messages && this.state.general_condolence_messages.map((item,idx) =>(
                      <div className="single_message" key={idx}>
                        <div className="message" onClick={(e) => this.message_change(e)}>{item.message}</div>
                        <div className="full_message" onClick={(e) => this.message_change(e)}>{item.message}</div>
                      </div>
                    ))}
                    
                  </div>
                  <div style={{textAlign:"center"}}>
                    <button className="new_death_notice_message_button condolence_button" 
                      onClick={(e) => {this.show_form(e,"condolence")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>
                      <span className="button_label"> Leave a Condolence</span>
                    </button>
                  </div>
                  <div className="tribute_messages_container">
                    {this.state.showing_condolence_messages && this.state.showing_condolence_messages.map((item, idx) => (
                      <div className="single_tribute_message" key={idx} id={item.id}>
                         {/* {this.context.profile_img_src?
                        <div className="tribute_type_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+item.profile_img_src+"')"}}></div>
                        :
                        <div className="tribute_type_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                        } */}
                         {item.profile_img_src?
                          (!item.login_type)?
                            <div className="tribute_type_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+item.profile_img_src+"')"}}></div>
                            :
                            <img className="tribute_type_image" src={(item.profile_img_src)} referrerPolicy="no-referrer"/>
                          :
                          <div className="tribute_type_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                        }
                        <div className="name_address_container">
                          <div className="posted_details">{item.written_by}</div>
                          <div className="location">{item.user_location}</div>
                        </div>
                        <div className="date_tribute_text_buttons_grid">
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                            })}</div>
                          <div className="message">{item.tribute_text}</div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"condolence",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    ))} 
                  </div>
                  <div className="show_more_messages_button"
                    onClick={() => this.show_more_message("condolence")}
                    style={{display:this.state.death_notice_messages.length == this.state.showing_condolence_messages.length?"none":"inline-block"}}
                  >Show more</div>
                  <div className="show_less_messages_button"
                    onClick={() => {
                      this.setState({
                        showing_condolence_messages : this.state.death_notice_messages.slice(0,3),
                      },() => {
                        window.scroll({
                          top: (this.condolence_ref.current.offsetTop-20), 
                          left: 0, 
                          behavior: 'smooth' 
                        });
                      });
                    }}
                    style={{display:this.state.death_notice_messages.length > 3 && this.state.death_notice_messages.length == this.state.showing_condolence_messages.length && this.state.death_notice_messages.length?"inline-block":"none"}}
                  >Show less</div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list"
              ref={this.mementos_ref}
              id="mementos"
            >
              <div className="single_info_content" style={{backgroundColor:"rgb(200 206 215 / 65%)"}}>
                <div className="single_info_head">
                  <h1>MEMENTOES</h1>
                </div>
                <div className="single_info_body" style={{textAlign:"center"}}>
                  <form className="new_tribute_form tribute_form" style={{backgroundColor:""}} onSubmit={ (e) => this.tribute_form_submit(e,"tribute")} method="post">
                    <div className="form_body">
                      <div className="tribute_types_container">
                        <label className="single_tribute_type">
                          <input
                            type="radio"
                            name="tribute_type"
                            value="candle"
                            onChange={this.tribute_type_change}
                            checked={this.state.tribute_type == "candle"}
                          />
                          <div className={this.state.tribute_type == "candle" ? "image_label_container tribute_type_selected" : "image_label_container"} >
                            <div className="tribute_type_image" style={{ backgroundImage: "url('" + candle + "')" }}></div>
                            <div className="tribute_type_label"> Light a Candle</div>
                          </div>
                        </label>
                        <label className="single_tribute_type">
                          <input
                            type="radio"
                            name="tribute_type"
                            value="flower"
                            onChange={this.tribute_type_change}
                            checked={this.state.tribute_type == "flower"}
                          />
                          <div className={this.state.tribute_type == "flower" ? "image_label_container tribute_type_selected" : "image_label_container"}>
                            <div className="tribute_type_image" style={{ backgroundImage: "url('" + flower + "')" }}></div>
                            <div className="tribute_type_label"> Lay a Flower</div>
                          </div>
                        </label>
                      </div>
                      <div className="tribute_type_images_container candle_images" style={{display:this.state.tribute_type=="candle"?"block":"none"}}>
                        {this.state.candle_images && this.state.candle_images.map((item,idx) => (
                          <div className="single_tribute_type_image"
                            key={idx}
                            onClick={() => this.tribute_image_change(item)}
                            style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')","borderColor":this.state.current_tribute_image==item.tribute_type_image?"#bd5895":""}}></div>
                        ))}
                      </div>
                      <div className="tribute_type_images_container flower_images" style={{display:this.state.tribute_type=="flower"?"block":"none"}}>
                        {this.state.flower_images && this.state.flower_images.map((item,idx) => (
                          <div className="single_tribute_type_image"
                            key={idx}
                            onClick={() => this.tribute_image_change(item)}
                            style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')","borderColor":this.state.current_tribute_image==item.tribute_type_image?"#bd5895":""}}></div>
                        ))}
                      </div>
                      <div className="form_footer">
                        <div></div>
                        <div className="form_footer_spacer"></div>
                        <ButtonTheme2 label="Publish" className="save_tribute_button" />
                      </div>

                    </div>
                  </form>
                  <div className="mementos_container">
                    {this.state.showing_mementos && this.state.showing_mementos.map((item, idx) => (
                      <div className="single_memento_container" id={item.id} key={idx}>
                        <div className="label_quotes_grid">
                          <div className="mementos_label">MEMENTOES</div>
                          <div className="quotes">
                            {item.tribute_type == "candle"?
                            <div>
                              Your love will light our way.  Your memory will forever be with us.
                            </div>
                            :
                            <div>
                              <span>Your life was a blessing,</span>
                              <span> your memory a treasure, </span>
                              <span>and now missed beyond measure.</span>
                            </div>    
                          }</div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"tribute",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            {/* <div className="report_button" style={{marginTop:"5px"}} onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div> */}
                          </div>
                        </div>
                        <div className="published_details">
                          <div className="published_by">{item.written_by}</div>
                          <div className="location">{item.user_location}</div>
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                          weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                          })}</div>
                        </div>
                        <div className="tribute_type_image" style={{ backgroundImage: item.tribute_type_image?"url('"+process.env.REACT_APP_CDN_URL+"/tribute_images/"+item.tribute_type_image+"')" :item.tribute_type == "candle" ?"url('" + candle + "')" : item.tribute_type == "flower" ? "url('" + flower + "')" : "url('" + note + "')" }}></div>
                      </div>
                    ))} 
                  </div>
                  <div className="show_more_messages_button"
                    onClick={() => this.show_more_message("mementos")}
                    style={{display:this.state.death_notice_tributes.length == this.state.showing_mementos.length?"none":"inline-block"}}
                  >Show more</div>
                  <div className="show_less_messages_button"
                    onClick={() => {
                      this.setState({
                        showing_mementos : this.state.death_notice_tributes.slice(0,3),
                      },() =>{
                        window.scroll({
                          top: (this.mementos_ref.current.offsetTop-20), 
                          left: 0, 
                          behavior: 'smooth' 
                        });
                      });
                    }}
                    style={{display:this.state.death_notice_tributes.length > 3 && this.state.death_notice_tributes.length == this.state.showing_mementos.length && this.state.death_notice_tributes.length?"inline-block":"none"}}
                  >Show less</div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.prayer_ref} id="prayer">
              <div className="single_info_content">
                <div className="single_info_head">
                  <h1>OFFER A PRAYER</h1>
                </div>
                <div className="single_info_body">
                  <form className="new_tribute_form prayer_form" onSubmit={(e) => this.tribute_form_submit(e,"prayer")} method="post">
                    <div className="form_body">
                      <div className="tribute_message_grid">
                        <div className="selected_tribute_type_image" style={{ backgroundImage:  "url('"+ note + "')"}}></div>
                        <div className="tribute_message">
                          <textarea
                            name="prayer_tribute"
                            value={this.state.prayer_tribute}
                            placeholder="Write your prayer (Max 700 characters)"
                            rows={5}
                            onChange={(e) => this.form_change(e,"prayer")}
                            required
                          />
                        </div>
                      </div>
                      <div className="form_footer">
                        <div className="show_hide_prayers_button">Show default prayers</div>
                        <div className="hide_tribute_form_button" onClick={(e) => {this.hide_form("prayer")}}>HIDE</div>
                        <ButtonTheme2 label="Publish" className="save_tribute_button" />
                      </div>
                      <div className="disclaimer_container" style={{color:"white"}}>
                        <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                        <div className="disclaimer_text" >
                          Please write your correct name and other information requested.
                          Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                            indecent, discriminatory, or unlawful or similar comments. 
                            Amrityum.com will not be responsible for any defamatory message posted 
                            under this notice.
                            
                          Please note that sending false messages to insult, defame, intimidate, mislead,
                            or deceive people or to intentionally cause public disorder is punishable
                            under law. It is obligatory on amrityum.com to provide all details we
                              hold of senders of such comments, to the authority concerned upon request.
                        
                          Hence, sending offensive comments using amrityum will be purely at your own risk,
                            and in no way will amrityum.com be held responsible.
                        </div>
                        <span className="read_or_hide_disclaimer">Read More</span>
                      </div>  
                    </div>
                  </form>
                  <div className="general_prayers_container">
                    {this.state.general_prayers && this.state.general_prayers.map((item,idx) =>(
                      <div className="single_message" key={idx}>
                        <div className="message" onClick={(e) => this.prayer_change(e)}>{item.prayer}</div>
                        <div className="full_message" onClick={(e) => this.prayer_change(e)}>{item.prayer}</div>
                      </div>
                    ))}
                    
                  </div>
                  <div style={{textAlign:"center"}}>
                    <button className="new_death_notice_message_button prayer_button" 
                      onClick={(e) => {this.show_form(e,"prayer")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>
                      <span className="button_label"> Leave a prayer</span>
                    </button>
                  </div>
                  <div className="prayers_container">
                    {this.state.showing_prayers && this.state.showing_prayers.map((item, idx) => (
                      <div className="single_prayer_container" key={idx} id={item.id}>
                        <div className="prayer_published_details">
                          <RiDoubleQuotesL className="quote_icon" />
                          {item.profile_img_src?
                          (!item.login_type)?
                            <div className="user_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+item.profile_img_src+"')"}}></div>
                            :
                            <img className="user_image" src={(item.profile_img_src)} referrerPolicy="no-referrer"/>
                          :
                          <div className="user_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                          }
                          <div className="posted_details">
                            <div className="user_name">{item.written_by}</div>
                            <div className="user_location">{item.user_location}</div>
                            <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                              weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                              })}</div>
                          </div>
                        </div>
                        <div className="prayer_details">
                          <div className="prayer_quote_grid">
                            <RiDoubleQuotesL className="mobile_quote_icon" />
                            <div className="prayer">{item.tribute_text}</div>
                            <RiDoubleQuotesR className="quote_icon" />
                          </div>
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"prayer",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/>
                                <AiFillHeart color="#ff365f" className="like_icon" style={{display:item.liked?'block':"none"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">Report </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                  </div>
                  <div style={{textAlign:"center"}}>
                    <div className="show_more_messages_button"
                      onClick={() => this.show_more_message("prayer")}
                      style={{display:this.state.death_notice_prayers.length == this.state.showing_prayers.length?"none":"inline-block"}}
                    >Show more</div>
                    <div className="show_less_messages_button"
                      onClick={() => {
                        this.setState({
                          showing_prayers : this.state.death_notice_prayers.slice(0,3),
                        },() =>{
                          window.scroll({
                            top: (this.prayer_ref.current.offsetTop-20), 
                            left: 0, 
                            behavior: 'smooth' 
                          });
                        });
                      }}
                    style={{display:this.state.death_notice_prayers.length > 3 && this.state.death_notice_prayers.length == this.state.showing_prayers.length  && this.state.death_notice_prayers.length?"inline-block":"none"}}
                  >Show less</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.memoirs_ref} id="memory_stories">
              <div className="single_info_content" style={{backgroundColor:"#d0dde0"}}>
                <div className="single_info_head">
                  <h1>MEMOIRS</h1>
                </div>
                <div className="single_info_body">
                  <form className="new_memory_story_form memory_form" onSubmit={(e) => this.tribute_form_submit(e,"memoirs")} method="post" encType="multipart/form-data">
                    <div className="form_body">
                      <div className="memory_story_grid">
                        {/* <textarea
                          name="tribute_text"
                          value={this.state.tribute_text}
                          placeholder="Write your memory (Max:3000 characters)"
                          rows={5}
                          onChange={this.form_change}
                          required
                        /> */}
                         <div className="memory_image_container">
                          <ImageUpload 
                            className="image_upload_component"
                            attrs={{
                              name: "image",
                              accept: "image/png, image/gif, image/jpeg"
                            }}
                            onChange={this.image_change}
                            deleteImage={this.delete_image}
                            src = {this.state.memory_image_src?process.env.REACT_APP_CDN_URL+"/"+this.state.memorial.entered_by+"/memory_images/"+this.state.memory_image_src:""}
                          />
                        </div>
                        <Editor
                          editorState={this.state.editorState}
                          toolbarClassName="editor_toolbar"
                          wrapperClassName="editor_component"
                          editorClassName="editor_input_area"
                          onEditorStateChange={this.onEditorStateChange}
                          placeholder="Write your memory (Max:3000 characters)"
                          handlePastedText={() => false}
                          stripPastedStyles={true}
                          toolbar={{
                            options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'remove', 'history'],
                            inline: {
                              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                            },list: {
                              options: ['unordered', 'ordered'],
                            },
                            fontSize: {
                              options: [14, 16, 18, 20, 22, 24, 26, 28, 32, 36],
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="disclaimer_container" style={{color:"white"}}>
                      <span style={{fontweight:"500",color:"darkred"}}>Disclaimer:</span> 
                      <div className="disclaimer_text" >
                        Please write your correct name and other information requested.
                        Kindly do not post any personal, abusive, defamatory, infringing, obscene,
                          indecent, discriminatory, or unlawful or similar comments. 
                          Amrityum.com will not be responsible for any defamatory message posted 
                          under this notice.
                          
                        Please note that sending false messages to insult, defame, intimidate, mislead,
                          or deceive people or to intentionally cause public disorder is punishable
                          under law. It is obligatory on amrityum.com to provide all details we
                            hold of senders of such comments, to the authority concerned upon request.
                      
                        Hence, sending offensive comments using amrityum will be purely at your own risk,
                          and in no way will amrityum.com be held responsible.
                      </div>
                      <span className="read_or_hide_disclaimer">Read More</span>
                    </div>  
                    <div className="form_footer">
                      <button className="show_hide_memories_button" type="button">
                          <div className="button_ghost"></div>
                          <span className="button_label">Hide</span>
                       </button>
                      <button className="save_memory_story_button" style={{display:this.state.editorState.getCurrentContent().hasText()?"inline-block":"none"}}> <div className="button_ghost"></div>Publish</button>
                    </div>
                  </form>
                  <div style={{textAlign:"center"}}>
                    <button className="new_memorial_message_button memory_button" 
                      onClick={(e) => {this.show_form(e,"memory")}}
                    >
                      <div className="button_ghost"></div>
                      <i className="fas fa-pencil-alt"></i>&nbsp;
                      <span className="button_label">BRING MEMORIES TO LIFE</span>
                    </button>
                  </div>
                  <div className="memories_stories_container">
                    {this.state.showing_memoirs && this.state.showing_memoirs.map((item, idx) => (
                      <div className="single_memory"  key={idx} id={item.id}>
                        {item.image_src?
                        <div className="memory_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/tribute_images/"+item.image_src+"')"}} />
                        :
                        <div className="memory_image" style={{backgroundImage:"url('"+memories+"')"}} />
                        }
                        <div className="memory_details">
                          <div className="posted_by">{item.written_by}</div>
                          <div className="posted_date">{new Date(item.published_date.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(),{
                            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                            })}</div>
                          {/* <div className="memory_content">{item.tribute_text} </div> */}
                          <div className="memory_content" dangerouslySetInnerHTML={{ __html: item.tribute_text }}/>
                          {/* <div className="show_more_button">
                            <i className="fa fa-chevron-up up_arrow"></i>
                            <div className="label">Show more</div>
                            <i className="fa fa-chevron-down down_arrow"></i>
                          </div> */}
                          <div className="like_report_grid">
                            <div className="likes_container">
                              <div className="like_button"
                                onClick={(e) => this.on_like_button_click(item.id,"memoirs",e)}
                                style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                              >
                                <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                                {/* <AiOutlineHeart color="white" className="like_icon" style={{display:item.liked?"none":"block"}}/> */}
                                <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                              </div>
                              <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}>  {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                            </div>
                            <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                              <GoReport />
                              <div className="report_label">REPORT </div> 
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    ))} 
                  </div>
                  <div style={{textAlign:"center"}}>
                    <div className="show_more_messages_button"
                      onClick={() => this.show_more_message("memories")}
                      style={{display:this.state.death_notice_memoirs.length == this.state.showing_memoirs.length?"none":"inline-block"}}
                    >Show more</div>
                    <div className="show_less_messages_button"
                      onClick={() => {
                        this.setState({
                          showing_memoirs : this.state.death_notice_memoirs.slice(0,3),
                        },() => {
                          window.scroll({
                            top: (this.memoirs_ref.current.offsetTop-20), 
                            left: 0, 
                            behavior: 'smooth' 
                          });
                        });
                      }}
                      style={{display:this.state.death_notice_memoirs.length > 3 && this.state.death_notice_memoirs.length == this.state.showing_memoirs.length && this.state.death_notice_memoirs.length?"inline-block":"none"}}
                    >Show less</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.donate_ref} id="donate_block">
              <div className="single_info_content" style={{backgroundColor:"white"}}>
                {/* <div className="single_info_head">
                  <h1>Donate to charity</h1>
                </div> */}
                <div className="single_info_body">
                  <div className="donate_container" style={{gridTemplateColumns:this.state.charity && this.state.charity.id?"auto 250px":"auto"}}>
                    <div>
                      <div className="donate_title">DONATE TO CHARITY</div>
                      {
                      this.state.charity && this.state.charity.id?
                      <div className="charity_description">
                        In Memory donations are one of the best ways to honour the lives of those who have passed away.
                        Please find below the charity recommended by <b style={{color:"#5a2d8f"}}>{this.state.death_notice.first_name} {this.state.death_notice.surname}</b>'s family. Every contribution you make brings a positive change in the lives of the neediest.
                      </div>
                      :
                      <div className="charity_description">
                        In Memory donations are one of the best ways to honour the lives of those who have passed away. Please find below charities you may like to support in memory of someone special. Every contribution you make brings a positive change in the lives of the neediest. 
                      </div>
                      }
                      <div className="charity_description">Alternatively, you may also view here our complete charity database to make donations.</div>
                      <div className="charity_description">Please note: Amrityum does not collect or process the donation.</div>
                      <div className="button_container">
                      <Link to={"/Charity/"+this.state.charity.id} 
                        className="donate_now_button"
                        style={{display:this.state.charity && this.state.charity.id?"inline-block":"none"}} target={"_blank"}
                      >Donate Now</Link>
                      <Link to="/Charities" className="all_charity_button" target={"_blank"}>All Charities</Link>
                      </div>
                    </div>
                    <div className="selected_notice" style={{display:this.state.charity && this.state.charity.id?"inline-grid":"none"}}>
                      {/* <div className="selected_label">Charity Details</div> */}
                      <Link className="single_charity" to={"/Charity/"+this.state.charity.id} target={"_blank"}> 
                        <div className="charity_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity.image_src+"')"}}></div>
                        <div className="charity_name">{this.state.charity.name}</div>
                        <div className="charity_address">{this.state.charity.locality}{this.state.charity.city?", "+this.state.charity.city:""}{this.state.charity.state?", "+this.state.charity.state:""}{this.state.charity.country}</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list mobile_buttons_block">
              <div className="single_info_content" style={{backgroundColor:"#dfd1e0"}}>
                <div className="single_info_body">
                  <div className="mobile_buttons_container">
                    <Link className="custom_button" to="/Feedback" style={{pointerEvents:this.state.preview?"none":"auto"}}>
                      <div className="button_label">Feedback</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link>
                    {/* <Link className="custom_button" to="#">
                      <div className="button_label">Donate to Charity</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link> */}

                    <Link className="custom_button"
                      style={{display:this.state.family_notice_mapped_id?"grid":"none",pointerEvents:this.state.preview?"none":"auto"}}
                      to={"/InMemoryNotices/"+this.state.family_notice_mapped_id}
                      target="_blank"
                      rel="noopener noreferrer" >
                      <div className="button_label">In Memory Notice</div>
                      <ImArrowRight2 size={16}  className="arrow_icon"/>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list" ref={this.contact_admin_ref} id="contact_admin_block">
              <div className="single_info_content" style={{backgroundColor:"#d8d9db"}}>
                <div className="single_info_head">
                  <h1>CONTACT PAGE ADMIN</h1>
                </div>
                <div className="single_info_body">
                  <form className="contact_page_admin_form" onSubmit={this.contact_admin_form_submit}>
                    <div className="inputs_grid">
                      <input
                        name = "name"
                        type = "text"
                        value ={ this.state.name}
                        onChange = {this.contact_form_change}
                        required = {true}
                        placeholder="Full Name *"
                      />
                      <input
                        name = "email"
                        type = "email"
                        value = {this.state.email}
                        onChange = {this.contact_form_change}
                        required = {true}
                        placeholder="Email Address *"
                      />
                    </div>
                    <textarea
                      name="message"
                      value={this.state.message}
                      rows={2}
                      onChange={this.contact_form_change}
                      maxLength={200}
                      required
                      placeholder="Message *"
                    />
                    <div className="maximum_character_label">Max 200 characters</div>
                    <ReCAPTCHA 
                       sitekey={process.env.REACT_APP_SITE_KEY} 
                       ref={this.captchaRef}
                    />
                    <button className="save_message_button">
                      <div className="button_ghost"></div>
                      <div className="button_label">SEND</div>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="single_info_container single_list buttons_block">
              <div className="single_info_content" style={{backgroundColor:"#dfd1e0"}}>
                <div className="single_info_body">
                  <div className="buttons_container">
                    <div className="back_button custom_button"
                      onClick={() => {this.props.history.goBack();}}
                    >
                      <div className="icon_container">
                        <MdOutlineKeyboardBackspace className="icon"/>
                      </div>
                      <div className="button_label">Back</div>
                      {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
                    </div>
                    <div className="report_page_button custom_button"
                      onClick={this.show_report_page_form}
                    >
                      <div className="icon_container">
                        <GoReport className="icon"/>
                      </div>
                      <div className="button_label">Report this page</div>
                      {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
                    </div>
                    <div className="share_button custom_button">
                      <div className="icon_container">
                        <MdOutlineShare className="icon"/>
                      </div>
                      <div className="button_label">Share</div>
                      {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
                      <div className="share_buttons_container">
                        <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(this.state.page_share_url)} target={"_blank"}>
                          <i className="fab fa-facebook-f share_icon" style={{color:"#0f92f3"}}></i>
                          <span>Facebook</span>
                        </a>
                        <a href={"https://api.whatsapp.com/send?text=" + this.state.fullname + " " + encodeURIComponent(this.state.page_share_url)} data-action="share/whatsapp/share" target={"_blank"}>
                          <i className="fab fa-whatsapp share_icon" style={{color:"#40bf50"}}></i>
                          <span>WhatsApp</span>
                        </a>
                        {/* <i className="fab fa-instagram share_icon"></i> */}
                        <a href={"mailto:?subject=Death Notice of "+this.state.fullname +"&body=" +this.state.fullname+ " " + this.state.page_share_url} target={"_blank"}>
                          <i className="far fa-envelope-open share_icon" style={{color:"#d10a0a"}}></i>
                          <span>Mail</span>
                        </a>
                        <a href={"https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.state.page_share_url)} target={"_blank"}>
                          <i className="fab fa-twitter share_icon" style={{color:"#4ea6e9"}}></i>
                          <span>Twitter</span>
                        </a>
                        <a onClick={() => {
                          var copyURL = this.state.page_share_url;
                          navigator.clipboard.writeText(copyURL);
                          $.alert("URL copied to clipboard");
                          setTimeout(() => {
                            $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                          },2000);
                        }}>
                           <i className="fa fa-clone share_icon" style={{color:"grey"}}></i>
                          <span>Copy URL</span>
                        </a>
                      </div>
                    </div>

                    <div className="print_button custom_button"
                      onClick={() => {window.print();}}
                      >
                      <div className="icon_container">
                        <MdOutlinePrint className="icon"/>
                      </div>
                      <div className="button_label">Print</div>
                      {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
                    </div>
                  </div>
                  <p className="about_amrityum">Amrityum strives to create a virtual platform for people to come together, get
                    connected and remember and celebrate their deceased loved ones by
                    publishing Death &amp; Family Notices, Create a Death notice, as well as preserve
                    our own stories and make them enduring by creating Life Events &amp; Family
                    Tree. Immortalise your tamed companions with Pet Tributes.</p>
                </div>
                <div className="logo_container">
                  <img src={logo} width="200" />
                </div>
              </div>
            </div>
            <div className="approve_reject_buttons_container" style={{display : this.state.preview?"block":"none"}}>
              <ButtonTheme1
                label="<< Back"
                className="previous_page_button"
                onClick={() => this.props.history.goBack()}
              ></ButtonTheme1>
              {this.state.death_notice.status == "PENDING" && this.context.session.user_type == "admin"?
              <>
              <ButtonTheme1
                label="Reject"
                className="reject_button"
                onClick={() => { this.rejectDeathNotice() }}
                style={{display:this.state.death_notice.status == "DELETED"?"none":"inline-block"}}
              ></ButtonTheme1>
              <ButtonTheme1
                label="Approve"
                className="approve_button"
                onClick={() => { this.approveDeathNotice() }}
                style={{display:this.state.death_notice.status == "APPROVED"?"none":"inline-block"}}
              ></ButtonTheme1>
              </>:""  }
            </div>
          </div>
          <div className="death_notice_sidebar_container">
            <div className="sidebar">
              {this.state.death_notice.image_src?
              <div className="deceased_image_icon" onClick={(e) => this.toggleChange(e,this.about_ref)} style={{ backgroundImage: "url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.death_notice.entered_by+"/death_notice_images/"+this.state.death_notice.image_src+"')"}}></div>
              :
              <div className="deceased_image_icon" onClick={(e) => this.toggleChange(e,this.about_ref)} style={{backgroundImage: "url('"+default_image+"')"}}></div> 
              }
              <div className="deceased_name" onClick={(e) => this.toggleChange(e,this.about_ref)}>{this.state.death_notice.first_name} {this.state.death_notice.surname}</div>
              {this.state.short_age?
              <div className="duration">({this.state.short_age})</div>
              :
              <div className="duration">({new Date(this.state.death_notice.date_of_birth).getFullYear()} - {new Date(this.state.death_notice.date_of_death).getFullYear()})</div>
              }
              <div className="deceased_address">
                <div>{this.state.death_notice.family_or_house_name}</div>
                <div>{this.state.death_notice.locality}</div>
                <div>{this.state.death_notice.city}</div>
                <div>{this.state.death_notice.state}</div>
                <div>{this.state.death_notice.country}</div>
              </div>
              <div className="toggles_container">
                <div className="toggle toggle_selected" 
                  onClick={(e) => this.toggleChange(e,this.death_notice_ref)}
                  id="death_notice_toggle"
                  >Death Notice</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.family_details_ref)}
                  id="family_details_toggle"
                  // style={{display:this.state.predeceased_peoples.length || this.state.survived_peoples.length?"block":"none"}}
                  >Family Details</div>
                   <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.funeral_details_ref)}
                  id="funeral_notice_toggle"
                  >Funeral Details</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.condolence_ref)}
                  id="condolence_toggle"
                  >Condolence Messages</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.mementos_ref)}
                  id="memento_toggle"
                  >Mementoes</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.prayer_ref)}
                  id="prayer_toggle"
                  >
                  Offer a Prayer</div>
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.memoirs_ref)}
                  id="memoir_toggle"
                  >
                  Memoirs</div>
                <Link  className="toggle" 
                  style={{display:this.state.family_notice_mapped_id?"inline-block":"none",pointerEvents:this.state.preview?"none":"auto"}}
                  to={"/InMemoryNotices/"+this.state.family_notice_mapped_id} 
                  target="_blank"
                  rel="noopener noreferrer" >
                  In Memory Notice</Link >
                <div className="toggle" 
                  onClick={(e) => this.toggleChange(e,this.donate_ref)}>
                  <span>Donate to Charity</span>
                </div>
                <a className="toggle" 
                  target={"_blank"} href="https://shop.amrityum.com" style={{pointerEvents:this.state.preview?"none":"auto"}}>Shop</a>
                {/* <div className="toggle" 
                  onClick={() => this.props.history.push("/Feedback")}>
                  <span>Feedback</span>
                </div> */}
                <Link className="toggle" style={{pointerEvents:this.state.preview?"none":"auto"}} to="/Feedback" target={"_blank"}>Feedback</Link>
                <div className="toggle"
                  id="contact_admin_toggle"
                  onClick={(e) => this.toggleChange(e,this.contact_admin_ref)}>
                  Contact Page Admin</div>
              </div>
              <div className="toggle_scroll_buttons_container">
                <BsChevronDoubleDown className="toggles_bottom_scroll_icon"></BsChevronDoubleDown>
                <BsChevronDoubleUp className="toggles_top_scroll_icon"></BsChevronDoubleUp>
              </div>
              <div className="share_icons_container">
                <a 
                  href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(window.location.href)}
                  target={"_blank"}
                >
                  <i className="fab fa-facebook-f share_icon" style={{padding:"9px 11px",color:"#0f92f3"}}></i>
                </a>
                <a
                  href={"whatsapp://send?text=" + this.state.death_notice.first_name + " URL: " + encodeURIComponent(window.location.href)} data-action="share/whatsapp/share" 
                  target={"_blank"}
                >
                  <i className="fab fa-whatsapp share_icon" style={{padding:"9px 11px",color:"#40bf50"}}></i>
                </a>
                {/* <i className="fab fa-instagram share_icon"></i> */}
                <a
                  href={"mailto:?subject=Death notice&body=" + this.state.death_notice.first_name + " URL: " + window.location.href}
                  target={"_blank"}
                >
                  <i className="far fa-envelope-open share_icon" style={{padding:"9px",color:"#d10a0a"}}></i>
                </a>
                <a
                  href={"ttps://twitter.com/intent/tweet?url=" + encodeURIComponent(window.location.href)}
                  target={"_blank"}
                >
                  <i className="fab fa-twitter share_icon" style={{padding:"9px",color:"#4ea6e9"}}></i>
                </a>
              </div>
            </div>
          </div>
          <div className="right_gap" ></div>
          {this.state.preview?
          "":
          <Footer/>
          }
        </div>
        <div className="report_page_form_ghost"
          onClick={() => $(".report_page_form_ghost").css("display","none")}
        >
          <form className="report_page_form" 
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.report_page_form_submit}
          >
            <div className="report_page_title">Report this Notice</div>
            <div className="leave_message_label">Reasons for reporting notice</div>
            <textarea
              name="reason"
              value={this.state.reason}
              rows={5}
              onChange={this.form_change}
              maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => $(".report_page_form_ghost").css("display","none")}
            >
              <div>x</div>
            </div>
          </form>
        </div>
        <div className="reject_reason_container" ref={this.reject_reason_container_ref}>
          <form className="reject_reason_form" onSubmit={this.reject_form_submit} method="post">
            <div>Reject Reason</div>
            <textarea name="reject_reason"
              rows={4}
              value={this.state.reject_reason}
              onChange={this.form_change}
              required
            />
            <button className="reject_form_submit_button">Submit</button>
            <i className="fa fa-times reject_form_close_button" onClick={() => { this.reject_reason_container_ref.current.style.display = "none"; this.setState({ reject_reason: "" }) }}></i>
          </form>
          
        </div>
      </div>
      </>

    )
  }
}

export default SingleDeathNotice;
SingleDeathNotice.contextType = MyContext;